<template>
<div class="d-flex"  style=" align-items: center; height: 80vh" >
	<div class="d-flex flex-column  align-center justify-center size"  >
		<v-img 
			src='/Assest/mirta.png'
			max-width="400px"
			height="200px"
			style=" margin:  auto  "
			class=""
		/>
		<span class="text-h6 text-center">Mirta</span>
		<p class="text-center overline">Starling - v{{version}}</p>
	</div>
</div>
</template>

<script>
import { VERSION as version } from '@/constants'

export default {
	data() {
		return {
			version
		}
	},

	metaInfo: {
		title: 'Home',
		titleTemplate: '%s | Mirta'
	},
}
</script>
<style scoped>
.size{
	height: 60%; 
	width:100%;
}
</style>