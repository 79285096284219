<template>
	<div>
		<div class="d-flex justify-space-between grey--text">
			<p class="primary--text title mb-0 d-flex align-center">Companies</p>
			<v-btn
				elevation="0"
				color="primary white--text"
				small
				class="my-2"
				@click="dialogCompany = true , AddOrEdit = false, editItemId = false"
			>
				Add Company
			</v-btn>
		</div>
		<!-- Start Dialog Edit-->
		<v-dialog
			v-model="dialogCompany"
			max-width="500px"
			content-class="rounded-lg"
		>
			<v-form ref="form" @submit.prevent="save">
				<v-card class="rounded-lg px-2 py-2">
					<v-card-title class="primary--text h6-text pb-0">
						{{editItemId ? 'Edit Company' : 'Add company'}}
					</v-card-title>
					<v-card-text class="py-2">
						<v-row>
							<v-col cols="6">
								<v-text-field
									v-model="name"
									type="text"
									label="name"
									outlined
									dense
									hide-details="auto"
									class="rounded-lg"
									:rules="rules.required"
								></v-text-field>
							</v-col>

							<v-col cols="6">
								<v-file-input
									v-model="file"
									label="file"
									outlined
									dense
									hide-details="auto"
									accept="image/*"
								></v-file-input>
							</v-col>
						</v-row>
					</v-card-text>
					<v-card-actions>
						<v-spacer></v-spacer>
						<v-btn
							color="primary"
							type="submit"
							:disabled="loadingDialog"
							:loading="loadingDialog"
						>
							Save
						</v-btn>
						<v-btn
							text
							color="primary"
							@click="dialogCompany = false"
							:disabled="loadingDialog"
						>
							Cancel
						</v-btn>
					</v-card-actions>
				</v-card>
			</v-form>
		</v-dialog>
		<!-- End Dialog Edit-->

		<!--Snackbar Delete  -->
		<v-snackbar
			v-model="snackbar"
			color="primary"
		>
			<p class="mb-0"> {{ AddOrEdit? 'Company added successfully' : 'Modified successfully' }} </p>
			<template v-slot:action="{ attrs }">
				<v-btn
					color="white"
					text
					v-bind="attrs"
					@click="snackbar = false"
				>
					Close
				</v-btn>
			</template>
		</v-snackbar>
		<!-- end snackbar delete -->

		<!-- Start Dialog Delete -->
		<v-dialog
			v-model="dialogDelete"
			max-width="500px"
			content-class="rounded-lg"
		>
			<v-card class="rounded-lg px-2 py-2">
				<v-card-title class="darkRed--text h6-text">
					Delete company
				</v-card-title>
				<v-card-text>
					Are you sure you want to delete this company?
				</v-card-text>
				<v-card-actions>
					<v-spacer></v-spacer>
					<v-btn
						color="darkRed"
						@click="deleteItem"
						:disabled="loadingDialog"
						:loading="loadingDialog"
						class="white--text"
					>
						Yes
					</v-btn>
					<v-btn
						text
						color="darkRed"
						@click="dialogDelete = false"
						:disabled="loadingDialog"
					>
						Cancel
					</v-btn>
				</v-card-actions>
			</v-card>
		</v-dialog>
		<!-- End Dialog Delete -->

		<!--Snackbar Delete  -->
		<v-snackbar
			v-model="snackbarDelete"
			color="darkRed"
		>
			<p class="mb-0">{{ textDelete }}</p>
			<template v-slot:action="{ attrs }">
				<v-btn
					color="white"
					text
					v-bind="attrs"
					@click="snackbarDelete = false"
				>
					Close
				</v-btn>
			</template>
		</v-snackbar>
		<!-- end snackbar delete -->

		<!-- Start table -->
		<v-data-table
			:headers="headers"
			:items="loading ? [] : companies"
			class="elevation-1 text-center"
			dense
			:persistent="loadingDialog"
			:loading="loading"
		>
			<template v-slot:item.logoPath="{ item }" >
				<v-img
					:src="BASE_URL +'/'+ item.logoPath"
					max-width="50"
					class="my-3 rounded-sm mx-auto"
					lazy-src="../assets/placeholder-image.png"
				></v-img>
			</template>

			<template v-slot:item.actions="{ item }">
				<v-btn
					text
					color="primary"
					dark
					elevation="0"
					min-width="20px"
					class="px-1"
					@click="editItemId = item.id; dialogCompany = true, loadingDialog = false, AddOrEdit = true;"
				>
					<v-icon>mdi-pencil</v-icon>
				</v-btn>

				<v-btn
					text
					color="darkRed"
					dark
					elevation="0"
					min-width="20px"
					@click="deleteItemId = item.id; dialogDelete = true, loadingDialog = false"
					class="px-1 mx-2"
				>
					<v-icon>mdi-delete</v-icon>
				</v-btn>

			</template>
		</v-data-table>

		<!-- End table -->
	</div>
</template>
<script>
import { mapState, mapGetters } from 'vuex'
import { BASE_URL } from '@/constants'
import rules from '@/helpers/validation rules'
export default {
	data () {
		return {
			img: '/Assest/mirta.png',
			// snakebar
			snackbar: false,

			// snakebarDelete
			snackbarDelete: false,
			textDelete: 'Deleted successfully',

			dialog: false,
			dialogCompany: false,
			loadingDialog: false,

			// loadingDialogDelete: false,

			AddOrEdit: false,

			file: null,
			// delete dialog
			dialogDelete: false,
			deleteItemId: null,

			name: '',
			id: null,
			loading: false,

			editItemId: null,
			confirmId: null,
			headers: [
				{ text: 'name', value: 'name', align: 'center' },
				{ text: 'logo', value: 'logoPath', align: 'center', sortable: false },
				{ text: 'Actions', value: 'actions', align: 'center', sortable: false }
			],

			BASE_URL,
			rules
		}
	},
	watch: {
		dialogCompany (val) {
			if (this.editItemId) {
				const item = this.getCompanyById(this.editItemId)
				this.name = item.name
			} else {
				this.$refs.form.reset()
			}
		}
	},
	computed: {
		...mapState({
			companies: state => state.Companies.companies
		}),
		...mapGetters({
			getCompanyById: 'Companies/getCompanyById'
		})
	},
	methods: {
		fetchCompanies () {
			this.loading = true
			this.$store.dispatch('Companies/fetchAll').finally(() => {
				this.loading = false
			})
		},
		save () {
			if (this.$refs.form.validate()) {
				this.loadingDialog = true
				if (this.editItemId) {
					this.AddOrEdit = true
					const updateName = this.$store.dispatch('Companies/update', {
						id: this.editItemId,
						name: this.name,
						logo: this.file
					}).then((data) => {}).finally(() => {
						this.AddOrEdit = false
					})
					if (this.file != null) {
						const uploadImage = this.$store.dispatch('Companies/uploadImage', { id: this.editItemId, logo: this.file })
						Promise.all([updateName, uploadImage]).then(() => {
							this.dialogCompany = false
							this.snackbar = true
							this.fetchCompanies()
							this.loadingDialog = false
						})
					} else {
						Promise.all([updateName]).then(() => {
							this.dialogCompany = false
							this.snackbar = true
							this.fetchCompanies()
							this.loadingDialog = false
						})
					}
				} else {
					this.$store.dispatch('Companies/create', { name: this.name })
						.then((data) => {
							if (this.file != null) {
								this.AddOrEdit = false
								const updateImg = this.$store.dispatch('Companies/uploadImage', { id: data, logo: this.file }).then(() => {
									this.fetchCompanies()
									this.snackbar = true
									this.loadingDialog = false
								}).catch(() => {
									this.fetchCompanies()
									this.snackbar = true
									this.loadingDialog = false
								})
								Promise.all([updateImg]).then(() => {
									this.loadingDialog = false
									this.dialogCompany = false
									this.AddOrEdit = true
								})
							} else {
								this.loadingDialog = false
								this.dialogCompany = false
								this.AddOrEdit = true
							}
						})
				}
			}
		},
		deleteItem () {
			this.loadingDialog = true
			this.$store.dispatch('Companies/delete', { id: this.deleteItemId })
				.then(() => {
					// this.fetchCompanies()
				}).finally(() => {
					this.fetchCompanies()
					this.snackbarDelete = true
					this.loadingDialog = false
					this.dialogDelete = false
				})
		}
	},
	created () {
		this.fetchCompanies()
	},
	metaInfo: {
		title: 'Companies',
		titleTemplate: '%s | Mirta'
	},
}
</script>
