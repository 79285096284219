import Vue from 'vue'
import VueRouter from 'vue-router'
import Login from '../views/Login.vue'
import HomeView from '../views/HomeView.vue'
import Users from '../views/Users'
import Company from '../views/Company.vue'

Vue.use(VueRouter)

const routes = [
	{
		path: '/',
		name: 'login',
		component: Login
	},
	{
		path: '/home',
		name: 'home',
		component: HomeView
	},
	{
		path: '/users',
		name: 'Users',
		component: Users
	},
	{
		path: '/company',
		name: 'Company',
		component: Company
	}
]
const router = new VueRouter({
	mode: 'history',
	base: process.env.BASE_URL,
	routes
})
export default router