<template>
	<div>
		<v-container class="width" >
			<v-layout>
				<v-flex sm12 md8 lg8  align-self-center  class="mx-auto" style="width:100%">
					<v-form  @submit.prevent="login" ref="form">
						<v-row class="center d-flex" >
							<v-col cols="3" sm="2">
								<v-spacer></v-spacer>
							</v-col>
							<v-col cols="6" sm="8">
								<v-img
									src='/Assest/mirta.png'
									width="400"
									height="300 "
								/>
							</v-col>
							<v-col cols="3" sm="2">
								<v-spacer></v-spacer>
							</v-col>
						</v-row>

						<v-text-field
							type="text"
							label="Username"
							v-model="username"
							:rules="rules.name"
							outlined
							dense
						/>

						<v-text-field
							label="Password"
							v-model="password"
							:type="showPassword ? 'text' : 'password'"
							required
							:append-icon="showPassword ? 'mdi-eye' : 'mdi-eye-off'"
							@click:append="showPassword = !showPassword"
							:rules="rules.password"
							outlined
							dense
						/>
						<div class="text-center">
							<v-btn
								type="submit"
								dark
								color="darkRed"
								class="rounded-lg"
								width="50%"
								large
								:loading="loading"
								:rules="rules.required"
							>
								Login
							</v-btn>
						</div>
					</v-form>
				</v-flex>
			</v-layout>
		</v-container>
		<v-snackbar
			v-model="snackLogin"
			color="darkRed"
		>
			{{message}}
			<template v-slot:action="{ attrs }">
				<v-btn
					color="white"
					text
					v-bind="attrs"
					@click="snackLogin = false"
				>
					Close
				</v-btn>
			</template>
		</v-snackbar>
	</div>
</template>
<script>
import rules from '@/helpers/validation rules'
export default {
	data () {
		return {
			username: '',
			password: '',
			userType: '',
			showPassword: false,
			loading: false,
			rules,
			message: '',
			snackLogin: false
		}
	},
	methods: {
		login () {
			if (this.$refs.form.validate()) {
				this.loading = true
				this.$store.dispatch('auth/login', {
					username: this.username,
					password: this.password
				}).then((data) => {
					if (data.userType === 1) {
						this.$router.replace({ name: 'home' });
					} else {
						this.$store.dispatch('auth/logout');
						this.message = 'Only admin can login to system.'
					}
				}).catch((e) => {
					this.message = e.response.data.message
				}).finally(() => {
					this.snackLogin = true;
					this.loading = false
				})
			}
		}
	},
	metaInfo: {
		title: 'Login',
		titleTemplate: '%s | Mirta'
	},
}
</script>

<style scoped lang="scss">
a {
    text-decoration: none;
}
.center {
	text-align: -webkit-center!important;
}
.width{
	width: 100%;
	@media only screen and (min-width:768px) {
		width: 50%;
	}
}
</style>
