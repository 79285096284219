<template>
	<v-app>
		<AppBar v-if="$route.name !== 'login' " @change-drawer="drawer = !drawer" ></AppBar>
		<navigation-drawer v-if="$route.name !== 'login'  " v-model="drawer" ></navigation-drawer>
		<v-main class="pt-0">
			<v-container fluid>
				<router-view/>
			</v-container>
		</v-main>
	</v-app>
</template>

<script>
import AppBar from '@/components/AppBar.vue'
import NavigationDrawer from '@/components/NavigationDrawer.vue'
export default {
	components: {
		AppBar,
		NavigationDrawer
	},
	data: () => ({
		drawer: false,
	})
}
</script>
