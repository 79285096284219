import Vue from 'vue'
import App from './App.vue'
import router from './router'
import store from './store'
import vuetify from './plugins/vuetify'
import axios from 'axios'
import * as types from './store/mutation-types'
import { BASE_URL } from './constants'
import VueMeta from 'vue-meta'
window.axios = axios.create({ baseURL: BASE_URL + '/api' })
if ('auth' in localStorage) {
	try {
		const auth = JSON.parse(localStorage.getItem('auth'));
		const authHeader = `Bearer ${auth.token}`;
		window.axios.defaults.headers.common.Authorization = authHeader;
	} catch (e) {
		store.dispatch(`auth/${types.LOGOUT}`);
		router.replace({ name: 'login' });
	}
} else {
	router.replace({ name: 'login' });
}

Vue.config.productionTip = false

Vue.use(VueMeta)

new Vue({
	router,
	store,
	vuetify,
	render: h => h(App)
}).$mount('#app')
