import * as types from '../mutation-types'

export default {
	namespaced: true,
	state: {
		Users: [],
		self: {
			name: null,
			userName: null,
			phoneNumber: null,
			email: null,
			password: null, 
			confirmPassword: null, 
			notes: null,
			// userType: null,
			companyId: null,
			userType: null,
			managersIds: null
		},
	},
	actions: {
		fetchAll({ commit }) {
			return window.axios.get('Users')
				.then((response) => {
					commit(types.STORE_USERS, response.data)
					return response.data
				})
		},
		fetchById({ commit }, { id }) {
			return window.axios.get(`/Users/${id}`)
				.then((response) => {
					return response.data
				})
		},
		create({ commit }, { name, userName, password, confirmPassword, phoneNumber, email, userType, notes, companiesId, managersIds }) {
			return window.axios.post('/Users', { name, userName, password, confirmPassword, userType, phoneNumber, email, notes, companiesId, managersIds })
		},
		// update({ commit }, { id }, { name, userName, password, confirmPassword, phoneNumber, email, notes, companyId }) {
		// 	return window.axios.put(`/Users/${id}`, { name, userName, password, confirmPassword, phoneNumber, email, notes, companyId })
		// },
		// fetchById({ commit }, { id }, { name, userName, password, confirmPassword, phoneNumber, email, notes, userType, mangersId, companyId }) {
		// 	return window.axios.put(`/Users/${id}`, { name, userName, password, confirmPassword, phoneNumber, email, notes, userType, mangersId, companyId })
		// },
		update({ commit }, { id, name, userName, password, confirmPassword, phoneNumber, email, notes, companiesId, userType, managersIds }) {
			return window.axios.put(`/Users/${id}`, { name, userName, password, confirmPassword, phoneNumber, email, notes, companiesId, userType, managersIds })
		},
		deleteItem({ commit }, { id }) {
			return window.axios.delete(`/Users/${id}/Delete`)
		},
		lock({ commit }, { id }) {
			return window.axios.put(`/Users/${id}/Lock`)
		},
		unLock({ commit }, { id }) {
			return window.axios.put(`/Users/${id}/Unlock`)
		}
	},
	mutations: {
		[types.STORE_USERS](state, Users, payload) {
			state.Users = Users;
		}
	},
	getters: {
		getUserById: state => id => state.Users.find(c => c.id === id)
	},
}