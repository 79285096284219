import * as types from '../mutation-types'

export default {
	namespaced: true,
	state: {
		companies: [],
	},
	actions: {
		fetchAll({ commit }) {
			return window.axios.get('/companies')
				.then((response) => {
					commit(types.STORE_COMPANIES, response.data)
					return response.data
				})
		},
		create({ commit }, { name }) {
			return window.axios.post('/companies', { name })
				.then((response) => {
					return response.data
				})
		},
		update({ commit }, { id, name }) {
			return window.axios.put(`companies/${id}`, null, { params: { name } })
		},
		delete({ commit }, { id }) {
			return window.axios.delete(`companies/${id}/delete`)
		},
		uploadImage({ commit }, { id, logo }) {
			const formData = new FormData();
			formData.append('logo', logo)
			return window.axios.post(`/companies/${id}/ModifyCompanyLogo`, formData)
		}
	},
	mutations: {
		[types.STORE_COMPANIES](state, companies) {
			state.companies = companies;
		}
	},
	getters: {
		getCompanyById: state => id => state.companies.find(c => c.id === id)
	},
}