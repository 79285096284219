import * as types from '../mutation-types';

export default {
	namespaced: true,
	state: {
		authenticated: false,
		self: {
			name: null,
			username: null,
		},
	},

	actions: {
		login({ commit }, { username, password }) {
			return window.axios.post('/Users/Authenticate', { username, password })
				.then((response) => {
					const auth = {
						id: response.data.id, name: response.data.name, token: response.data.token, userType: response.data.userType, authenticated: true
					};
					localStorage.setItem('auth', JSON.stringify(auth));
					window.axios.defaults.headers.common.Authorization = 'Bearer ' + response.data.token;
					commit(types.LOGIN, auth);
					return response.data
				})
		},
		logout({ commit }) {
			localStorage.removeItem('auth');
			delete window.axios.defaults.headers.common.Authorization;
			commit(types.LOGOUT);
		},
	},
	mutations: {
		[types.LOGIN](state, payload) {
			state.authenticated = true;
			state.self.name = payload.name;
			state.self.username = payload.username;
		},
		[types.LOGOUT](state) {
			state.authenticated = false;
			state.self.name = null;
			state.self.username = null;
		},
	},
	getters: {
	},
}