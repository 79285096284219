import Vue from 'vue'
import Vuex from 'vuex'
import auth from './modules/auth'
import Users from './modules/Users'
import Companies from './modules/Companies'

Vue.use(Vuex)

export default new Vuex.Store({
	modules: {
		auth,
		Users,
		Companies
	},
})
