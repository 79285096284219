<template>
	<div class="ma-8">
		<v-navigation-drawer
			id="navigation-drawer"
			v-model="localeValue"
			app
			dark
			:mobile-breakpoint="$vuetify.breakpoint.thresholds.md"
			color="primary"
			width="200"
			floating
			:permanent="$vuetify.breakpoint.mdAndUp"
			:mini-variant="$vuetify.breakpoint.mdAndUp && drawer"
			mini-variant-width="55"
		>
			<v-list
				nav
				dense
			>
				<v-list-item :to="{name: 'home' }" exact-path >
					<v-list-item-icon>
						<v-icon>mdi-home</v-icon>
					</v-list-item-icon>
					<v-list-item-title>Home</v-list-item-title>
				</v-list-item>
				<v-list-item :to="{name: 'Users' }" exact-path >
					<v-list-item-icon>
						<v-icon>mdi-account-multiple</v-icon>
					</v-list-item-icon>
					<v-list-item-title>Users</v-list-item-title>
				</v-list-item>

				<v-list-item v-if="false" :to="{name: 'Company' }" exact-path >
					<v-list-item-icon>
						<v-icon>mdi-domain</v-icon>
					</v-list-item-icon>
					<v-list-item-title>Company</v-list-item-title>
				</v-list-item>
			</v-list>
		</v-navigation-drawer>
	</div>
</template>
<script>

export default {
	name: 'NaigationDrawer',
	props: {
		drawer: { type: Boolean, default: false }
	},
	model: {
		prop: 'drawer'
	},
	computed: {
		localeValue: {
			get () {
				return this.drawer
			},
			set (newVal) {
				this.$emit('input', newVal)
			}
		}
	}
}
</script>
<style lang="scss">
#navigation-drawer {
	*::-webkit-scrollbar {
		width: 5px;
	}
}
</style>
