<template>
	<v-app-bar
		app
		color="primary"
		dark

	>
		<!-- toggle drawer -->
		<v-btn
			text
			fab
			small
			@click="$emit('change-drawer')"
			class="me-2"
		>
            <v-icon>mdi-menu</v-icon>
        </v-btn>
		<router-link :to="{ name: 'home' }">
			<v-img
				src='/Assest/mirta.png'
				max-width="75px"
			/>
		</router-link>
		<v-spacer></v-spacer>
		<h3> Starling</h3>
		<v-spacer></v-spacer>
		<v-menu
			:close-on-content-click="false"
			width="100"
			offset-y
		>
			<template v-slot:activator="{ on, attrs }">
				<v-btn
					color="primary"
					dark
					v-bind="attrs"
					v-on="on"
					elevation= '0'
				>
					<v-icon>
						mdi-dots-vertical
					</v-icon>
				</v-btn>
			</template>
			<v-card>
				<v-list dense>
					<v-list-item @click="logout">
						<v-list-item-content>
							Logout
						</v-list-item-content>
						<v-list-item-icon>
							<v-icon>mdi-logout</v-icon>
						</v-list-item-icon>
					</v-list-item>
				</v-list>
			</v-card>
		</v-menu>
	</v-app-bar>
</template>
<script>

export default {
	methods: {
		logout () {
			this.$store.dispatch('auth/logout').then(() => {
				this.$router.replace({ name: 'login' })
			})
		}
	},
	mounted () {
		if (this.$vuetify.breakpoint.mdAndUp) {
			this.$nextTick(() => {
				this.$emit('change-drawer')
			})
		}
	}
}
</script>
<style scoped>
.l{
	color: transparent;
}
</style>
