<template>
	<div>
		<div class="d-flex justify-space-between grey--text">
			<p class="primary--text title mb-0 d-flex align-center">Users</p>
			<v-btn
				elevation="0"
				color="primary white--text"
				small
				class="my-2 "
				@click="reset"
			>
				Add User
			</v-btn>
		</div>

		<!-- filter -->
		<div v-if="0" class="mb-5">
			<v-expansion-panels>
				<v-expansion-panel>
					<v-expansion-panel-header>
						Filter
					</v-expansion-panel-header>
					<v-expansion-panel-content>
						<v-row align="center">
							<v-col cols="4">
								<v-autocomplete
									type="search"
									label="Company"
									item-text='name'
									:items="companies"
									outlined
									dense
									v-model="search"
									hide-details
								/>
							</v-col>
							<v-col>
								<v-btn>
									Reset
								</v-btn>
							</v-col>
						</v-row>
					</v-expansion-panel-content>
				</v-expansion-panel>
			</v-expansion-panels>
		</div>

		<!-- Start Dialog Edit-->
		<v-dialog
			v-model="dialog"
			max-width="550"
			content-class="rounded-lg"
			persistent
		>
			<v-card class="rounded-lg px-4 py-4">
				<v-card-title class="primary--text text-h6 ">
					{{ editOrAdd? 'Edit user data' : 'Add new user' }}
				</v-card-title>
				<v-form @submit.prevent="save" ref="form">
					<v-card-text class="py-4">
						<v-row>
							<v-col class="py-0 px-0 mb-0" md="6" sm="6" cols="12">
								<v-text-field
									v-model="name"
									type="text"
									label="Name"
									outlined
									dense
									:loading="loadingUserData"
									:rules="rules.name"
								>
								</v-text-field>
							</v-col>

							<v-col class="py-0 ps-2 pe-0" md="6" sm="6" cols="12">
								<v-text-field
									v-model="userName"
									type="text"
									label="Username"
									outlined
									dense
									:loading="loadingUserData"
									:rules="rules.name"
								>
								</v-text-field>
							</v-col>
							<v-col class="py-0 px-0">
								<v-autocomplete
									v-model="companyId"
									:items="companies"
									item-text='name'
									item-value="id"
									label="company"
									outlined
									dense
									multiple
									:loading="loadingUserData"
									:rules="rules.required"
									readonly
									disabled
								></v-autocomplete>
							</v-col>
							<v-col class="py-0 ps-2 pe-0" md="6" sm="6" cols="12">
								<v-text-field
									v-model="email"
									type="email"
									label="Email"
									outlined
									dense
									:loading="loadingUserData"
									:rules="rules.email"
								>
								</v-text-field>
							</v-col>
							<v-col class="py-0 px-0" md="6" sm="6" cols="12">
								<v-text-field
									v-model="password"
									type="password'"
									label="password"
									outlined
									dense
									:loading="loadingUserData"
									:rules="!editItemId ? rules.password : []"
									:hint="editItemId? 'Enter new password to change' : null "
									:persistent-hint="Boolean(editItemId)"
								>
								</v-text-field>
							</v-col>
							<v-col class="py-0 ps-2 pe-0" md="6" sm="6" cols="12">
								<v-text-field
									v-model="confirmPassword"
									type="password"
									label="confirm Password"
									outlined
									dense
									:loading="loadingUserData"
									:rules="!editItemId ? [val => val === password || 'Not matched password'] : []"
								>
								</v-text-field>
							</v-col>
							<v-col class="py-0 px-0" md="6" sm="6" cols="12">
								<v-text-field
									v-model="phoneNumber"
									type="phone"
									label="phone number"
									outlined
									dense
									:loading="loadingUserData"
									:rules="rules.required"
								>
								</v-text-field>
							</v-col>

							<v-col class="py-0 ps-2 pe-0" md="6" sm="6" cols="12">
								<v-radio-group
									v-model.number="userType"
									row
									class="my-0 "
									hide-details="auto"
									:rules="rules.requiredAsNumber"
									:disabled="editOrAdd"
								>
									<v-radio
										label="Manger"
										:value="2"
									></v-radio>
									<v-radio
										label="Employee"
										:value="3"
									></v-radio>
								</v-radio-group>
							</v-col>

							<v-col class="py-0 px-0"  cols="12">
								<v-autocomplete
									chips
									small-chips
									deletable-chips
									multiple
									clearable
									dense
									label="Manger Name"
									outlined
									:loading="loadingUserData"
									v-model="managersIds"
									item-text='name'
									:items="users.filter((item) => item.userType === 2)"
									item-value="id"
								></v-autocomplete>
							</v-col>

							<v-col class="pt-0 px-0" cols="12">
								<v-textarea
									v-model="notes"
									outlined
									name="input-7-4"
									label="Notes"
									rows="1"
									auto-grow
									dense
									no-resize
									hide-details="auto"
								></v-textarea>
							</v-col>
						</v-row>

					</v-card-text>
					<v-card-actions>
						<v-spacer></v-spacer>
						<v-btn
							type="submit"
							color="primary"
							:loading="changeStateLoading"
							:disabled="changeStateLoading"
							class="white--text"
						>
							Save
						</v-btn>
						<v-btn
							text
							color="primary"
							@click="dialog = false"
							:disabled="changeStateLoading"
						>
							Cancel
						</v-btn>
						<!-- <v-spacer></v-spacer> -->
					</v-card-actions>
				</v-form>
			</v-card>
		</v-dialog>
		<!-- End Dialog Edit-->
		<v-snackbar
			v-model="snackError"
			color="darkRed"
		>
			{{message}}
		</v-snackbar>
		<!-- snackbar Edit -->
		<v-snackbar
			v-model="snackbarAdd"
			color="primary"
		>
			{{ editOrAdd? 'Modified successfully' : 'User added successfully'  }}
			<template v-slot:action="{ attrs }">
				<v-btn
					color="white"
					text
					v-bind="attrs"
					@click="snackbarAdd = false"
				>
					Close
				</v-btn>
			</template>
		</v-snackbar>
		<!-- end snackbar edit -->

		<!-- Start Dialog Delete -->
		<v-dialog
			v-model="dialogDelete"
			max-width="500px"
			content-class="rounded-lg"
		>
			<v-card class="rounded-lg px-2 py-2">
				<v-card-title class="darkRed--text text-h6">
					Delete user
				</v-card-title>
				<v-card-text>
					Are you sure you want to delete this user?
				</v-card-text>
				<v-card-actions>
					<v-spacer></v-spacer>
					<v-btn
						@click="deleteItem"
						color="darkRed"
						:loading="changeStateLoading"
						:disabled="changeStateLoading"
						class="white--text"
					>
						Yes
					</v-btn>
					<v-btn
						text
						color="darkRed"
						@click="dialogDelete = false"
						:disabled="changeStateLoading"
					>
						Cancel
					</v-btn>
				</v-card-actions>
			</v-card>
		</v-dialog>
		<!-- End Dialog Delete -->

		<!-- snackbar for delete -->
		<v-snackbar
			v-model="snackbar"
			color="darkRed"
		>
			<p class="mb-0">{{ deleteText }}</p>
			<template v-slot:action="{ attrs }">
				<v-btn
					color="white"
					text
					v-bind="attrs"
					@click="snackbar = false"
				>
					Close
				</v-btn>
			</template>
		</v-snackbar>
		<!-- end snackbar for delete -->

		<!-- Start Dialog Lock -->
		<v-dialog
			v-model="dialogLock"
			max-width="500px"
			content-class="rounded-lg"
		>
			<v-card class="rounded-lg px-2 py-2">
				<v-card-title class="primary--text h6-text">
					{{ userStatus === 2 ? 'Deactivating account' : 'Activating account' }}
				</v-card-title>
				<v-card-text>
					{{ userStatus === 2 ? 'Are you sure you want to deactivating this account?' : 'Are you sure you want to activating this account?' }}
				</v-card-text>
				<v-card-actions>
					<v-spacer></v-spacer>
					<v-btn
						@click="lockUnlock"
						color="primary"
						:loading="loadingDialog"
						:disabled="loadingDialog"
						class="mr-2 white--text"
					>
						Yes
					</v-btn>
					<v-btn
						text
						@click="dialogLock = false"
						color="primary"
						:disabled="loadingDialog"
					>
						Cancel
					</v-btn>
				</v-card-actions>
			</v-card>
		</v-dialog>
		<!-- End Dialog Lock -->

		<!-- snackbar Lock/unlock -->
		<v-snackbar
			v-model="snackbarLU"
			color="primary"
		>
			{{ userStatus === 2 ? 'Account is deactivated successfully' : 'Account is activated successfully'  }}
			<template v-slot:action="{ attrs }">
				<v-btn
					color="white"
					text
					v-bind="attrs"
					@click="snackbarLU = false"
				>
					Close
				</v-btn>
			</template>
		</v-snackbar>
		<!-- end snackbar  -->

		<!-- Start table -->
		<v-data-table
			:headers="headers"
			:items="loading ? [] : users"
			class="elevation-1 text-center"
			dense
			:loading="loading"
		>
			<template v-slot:item.actions="{ item }">
				<v-btn
					text
					color="primary"
					elevation="0"
					min-width="20px"
					class="px-1"
					@click="editItemId = item.id , dialog= true, changeStateLoading = false, editOrAdd = true"
					:disabled="item.userType === 1"
				>
					<v-icon>mdi-pencil</v-icon>
				</v-btn>

				<v-btn
					text
					color="darkRed"
					elevation="0"
					min-width="20px"
					@click="deleteItemId = item.id; dialogDelete = true; changeStateLoading = false"
					class="px-1 mx-2"
					:disabled="item.userType === 1"
				>
					<v-icon>mdi-delete</v-icon>
				</v-btn>
			</template>

			<template v-slot:item.userStatus="{item}">
				<div class="d-flex justify-center">
					<v-switch
						v-model.number="item.userStatus"
						color="primary"
						readonly
						@click="
							item.userType !== 1
								? (() => {lockItemId = item.id; dialogLock = true; userStatus= item.userStatus})()
								: null
						"
						:disabled="item.userType === 1"
						:true-value="2"
						:false-value="1"
						class="mt-0"
						hide-details
					></v-switch>
				</div>
			</template>

			<template v-slot:item.userType="{item}">
				<span v-if="item.userType===1">Admin</span>
				<span v-if="item.userType != 1"> {{ item.userType === 3? 'employee' : 'manger' }}  </span>
			</template>

			<template v-slot:item.companyId="{ item }">
				{{getCompanyById(item.companyId) ? getCompanyById(item.companyId).name : '-'}}
			</template>
		</v-data-table>
		<!-- End table -->
	</div>
</template>
<script>
import rules from '@/helpers/validation rules'
import { mapGetters, mapState } from 'vuex'
const fixedCompanyId = '1284de0b-96f6-4904-8166-08db1311329b';
export default {
	computed: {
		...mapState({
			users: state => state.Users.Users,
			companies: state => state.Companies.companies
		}),
		...mapGetters({
			getUserById: 'Users/getUserById',
			getCompanyById: 'Companies/getCompanyById'
		})
	},
	data () {
		return {
			// com: [{ name: 'Olabi', id: '1284de0b-96f6-4904-8166-08db1311329b' }],
			password: '',
			confirmPassword: '',
			rules,
			dialog: false,
			dialogDelete: false,
			dialogLock: false,
			deleteItemId: null,
			lockItemId: null,
			search: null,
			snackbarLU: false,
			changeStateLoading: false,
			loading: false,
			loadingUserData: false,
			showPassword: false,
			// column: null,
			row: null,
			checkbox: true,
			checkboxTwo: false,

			// Snackbar for Delete
			snackbar: false,
			deleteText: 'The user has been deleted',

			// Snackbar for Add or Edit
			snackbarAdd: false,

			// for lock or unlock account user
			stateUserAccount: true,
			editItemId: null,
			loadingDialog: false,
			editOrAdd: false,
			name: null,
			userName: null,
			phoneNumber: null,
			email: null,
			userStatus: false,
			companyId: [fixedCompanyId],
			userType: 1,
			managersIds: null,
			notes: null,
			message: null,
			snackError: false,
			headers: [
				{ text: 'Name', align: 'center', sortable: false, value: 'name' },
				{ text: 'Username', value: 'userName', align: 'center' },
				{ text: 'company', value: 'companyId', align: 'center' },
				{ text: 'User type', value: 'userType', align: 'center' },
				{ text: 'email', value: 'email', align: 'center' },
				{ text: 'Active', value: 'userStatus', align: 'center' },
				{ text: 'Actions', value: 'actions', sortable: false }
			]
		}
	},
	watch: {
		dialog (val) {
			if (val) {
				if (this.editItemId) {
					this.loadingUserData = true
					this.$store.dispatch('Users/fetchById', {
						id: this.editItemId
					}).then((data) => {
						this.name = data.name
						this.userName = data.userName
						this.phoneNumber = data.phoneNumber
						this.email = data.email
						this.password = data.password
						this.confirmPassword = data.confirmPassword
						this.notes = data.notes
						// this.companyId = data.companiesId
						this.managersIds = data.managers.map(c => c.id)
						this.userStatus = data.userStatus
						this.userType = data.userType
					}).finally(() => {
						this.loadingUserData = false
					})
				}
			} else {
				this.$refs.form.reset();
			}
			this.$nextTick(() => {
				this.companyId = [fixedCompanyId];
			})
		}
	},

	methods: {
		reset () {
			this.dialog = true
			this.editOrAdd = false
			this.editItemId = null
		},

		fetchUsers () {
			this.loading = true
			this.$store.dispatch('Users/fetchAll').finally(() => {
				this.loading = false
			})
		},
		save () {
			if (this.$refs.form.validate()) {
				this.changeStateLoading = true
				if (this.editItemId) {
					this.$store.dispatch('Users/update', {
						id: this.editItemId,
						name: this.name,
						userName: this.userName,
						phoneNumber: this.phoneNumber,
						email: this.email,
						password: this.password,
						confirmPassword: this.confirmPassword,
						notes: this.notes,
						companiesId: this.companyId,
						managersIds: this.managersIds ? this.managersIds : null,
						userType: this.userType
					}).then(() => {
						this.fetchUsers()
						this.editOrAdd = true
						this.dialog = false
						this.snackbarAdd = true
						this.$refs.form.reset()
					}).catch(e => {
						this.message = e.response.data.message
						this.snackError = true
					}).finally(() => {
						this.changeStateLoading = false
					})
				} else {
					this.editOrAdd = false
					this.$store.dispatch('Users/create', {
						name: this.name,
						userName: this.userName,
						phoneNumber: this.phoneNumber,
						email: this.email,
						password: this.password,
						confirmPassword: this.confirmPassword,
						notes: this.notes,
						companiesId: this.companyId,
						managersIds: this.managersIds ? this.managersIds : null,
						userType: this.userType
					}).then(() => {
						this.fetchUsers()
						this.dialog = false
						this.snackbarAdd = true
					}).catch(e => {
						this.message = e.response.data.message
						this.snackError = true;
					}).finally(() => {
						this.changeStateLoading = false
					})
				}
			}
		},
		deleteItem () {
			this.changeStateLoading = true
			this.$store.dispatch('Users/deleteItem', { id: this.deleteItemId })
				.then(() => {
					this.snackbar = true
					this.fetchUsers()
					this.dialogDelete = false
				}).finally(() => {
					this.changeStateLoading = false
					this.loadingDialog = true
				})
		},
		lockUnlock () {
			if (this.userStatus === 2) {
				this.loadingDialog = true
				this.$store.dispatch('Users/lock', { id: this.lockItemId }).then(() => {
					this.fetchUsers()
					this.snackbarLU = true
				}).finally(() => {
					this.dialogLock = false
					this.loadingDialog = false
				})
			} else if (this.userStatus === 1) {
				this.loadingDialog = true
				this.$store.dispatch('Users/unLock', { id: this.lockItemId }).then(() => {
					this.fetchUsers()
					this.snackbarLU = true
				}).finally(() => {
					this.loadingDialog = false
					this.dialogLock = false
				})
			}
		}
	},
	created () {
		this.fetchUsers()
		this.$store.dispatch('Companies/fetchAll')
	},
	metaInfo: {
		title: 'Users',
		titleTemplate: '%s | Mirta'
	},
}
</script>
